@import '~/styles/common/layout';

.wrapper {
  // NOTE: display: flex is required to count margins for offsetHeight
  @include column;

  margin: var(--endpoint-shadow-size);
  background-color: #fff;
  border-radius: 10px;
  user-select: none;
  box-shadow: 0 0 24px rgb(23 49 65 / 13%);

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 24px rgb(23 49 65 / 30%);
    transition: all ease-out 0.25s;
  }

  &:active {
    cursor: grabbing;
  }
}

.content {
  background-color: rgb(255 255 255 / 90%);
}
