@import '~@blueprintjs/core/lib/scss/variables';

// $pt-button-height: 26px;
// $pt-input-height: 26px;
// $pt-border-radius: 3px;

@import '~@blueprintjs/core/src/blueprint';
@import '~@blueprintjs/datetime/src/blueprint-datetime';
@import '~@blueprintjs/select/src/blueprint-select';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';

.bp4-control {
  margin-bottom: 0;
}
